import React from 'react';
import {graphql, navigate} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {BLOCKS, MARKS} from '@contentful/rich-text-types';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';

const options = {
    renderMark: {
        [MARKS.CODE]: text => <sup>{text}</sup>,
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => (
            <h2 className='head-3'>{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <h2 className='head-3'>{children}</h2>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <ul className='list-disc list-outside'>{children}</ul>
        ),
    },
};

const citationOptions = {
    renderMark: {
        [MARKS.CODE]: text => <sup>{text}</sup>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <p style={{marginBottom: 5.5}}>
                <small>{children}</small>
            </p>
        ),
    },
};

const ArticleTemplate = ({_, data}) => {
    const {title, content, citations} = data.contentfulArticle;

    return (
        <Layout>
            <Seo title={title}/>

            <div className='flex-grow max-w-4xl px-4 mx-auto mt-10 lg:px-0 dark-text'>
                <div className='flex flex-col w-full mb-12 text-left'>
          <span
              onClick={() => navigate(-1)}
              onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      navigate(-1).then(() => {
                      });
                  }
              }}
              role='button'
              tabIndex={0}
              className='text-sm cursor-pointer dark-text'
          >
            &larr; Back to all articles
          </span>
                </div>

                <div className='flex flex-col md:flex-row'>
                    <div className='w-full'>
                        <h1 className='mb-0'>{title}</h1>

                        <div className='font-light text-slim'>
                            <p className='italic'>By Adam J. Langino, Esq.</p>

                            {renderRichText(content, options)}

                            {citations && (
                                <>
                                    <hr className='mb-3'/>
                                    {renderRichText(citations, citationOptions)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query articleQuery($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      id
      content {
        raw
      }
      citations {
        raw
      }
    }
  }
`;

export default ArticleTemplate;
